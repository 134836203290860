import React from 'react';
import { Form, Input, Button, DatePicker, Radio } from 'antd';

const { Item } = Form;
const { TextArea } = Input;
const { Group } = Radio;

const { STATUS_OUTSTANDING, PRIORITY_LOW, PRIORITY_MEDIUM, PRIORITY_HIGH } = require('../../utils/values').default;

export default function () {
    const { getFieldDecorator } = this.props.form;

    return (
        <div className="app-tab-container">
            <Form onSubmit={this.handleFormSubmit} className="create-task-form">
                <Item>
                    {getFieldDecorator('name', {
                        rules: [
                            { required: true, message: 'Please input the task name!' },
                            { min: 9, message: 'Task name must be longer than 10 characters!' },
                        ],
                    })(<Input placeholder="Task name" />)}
                </Item>

                <Item>
                    {getFieldDecorator('description')(
                        <TextArea placeholder="Task description" autosize={{ minRows: 5, maxRows: 10 }} />
                    )}
                </Item>

                <Item>
                    {getFieldDecorator('date', {
                        rules: [{ required: true, message: 'Please input the task due date!' }],
                    })(<DatePicker placeholder="Task due date" />)}
                </Item>

                <Item>
                    {getFieldDecorator('priority', {
                        initialValue: PRIORITY_LOW,
                        rules: [{ required: true, message: 'Please select the task priority!' }],
                    })(
                        <Group>
                            <Radio value={PRIORITY_LOW}>Low priority</Radio>
                            <Radio value={PRIORITY_HIGH}>High priority</Radio>
                            <Radio value={PRIORITY_MEDIUM}>Medium priority</Radio>
                        </Group>
                    )}
                </Item>

                <Item className="m-0">
                    {getFieldDecorator('status', { initialValue: STATUS_OUTSTANDING })(
                        <Input type="hidden" />
                    )}
                </Item>

                <Button type="primary" htmlType="submit">Create task</Button>
            </Form>
        </div>
    );
}
