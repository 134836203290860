import React from 'react';
import { Tabs } from 'antd';

import CreateTask from '../CreateTask';
import OutstandingTasks from '../OutstandingTasks';
import CompletedTasks from '../CompletedTasks';

import logo from '../../assets/img/scorchsoft-logo-white.png';

const { TabPane } = Tabs;

export default function () {
    return (
        <div className="app">
            <div className="app-header">
                <img src={logo} alt="scorchsoft" />
            </div>

            <div className="app-body">
                <div className="app-container">
                    <Tabs defaultActiveKey="2">
                        <TabPane tab={this.renderTabTitle('plus', 'Create Task')} key="create">
                            <div className="app-tab-container">
                                <CreateTask onUpdate={this.handleTasksUpdate} />
                            </div>
                        </TabPane>

                        <TabPane tab={this.renderTabTitle('hourglass', 'Outstanding Tasks')} key="outstanding">
                            <div className="app-tab-container">
                                <OutstandingTasks onUpdate={this.handleTasksUpdate} />
                            </div>
                        </TabPane>

                        <TabPane tab={this.renderTabTitle('check', 'Completed tasks')} key="completed">
                            <div className="app-tab-container">
                                <CompletedTasks onUpdate={this.handleTasksUpdate} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>

            <div className="app-footer">
                <div>© 1019 Scorchsoft Ltd</div>
                <div>
                    <a
                        href="https://www.scorchsoft.com"
                        target="_blank"
                        rel="noopener noreferrer">Terms and conditions</a>
                </div>
            </div>
        </div>
    );
}
