import alertify from 'alertifyjs';

/**
 * Trigger a success alert
 * @param  string message
 * @return void
 */
const alert = (message) => {
    alertify.set('notifier','position', 'top-center');
    alertify.success(message, 3);
};

/**
 * Trigger a error alert
 * @param  string message
 * @return void
 */
const error = (message, consoleMessage) => {
    alertify.set('notifier','position', 'top-center');
    alertify.error(message, 3);

    if (consoleMessage) {
        console.error(consoleMessage);
    }
};

export { alert, error };
