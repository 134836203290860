/* global localStorage: true */
import { isEmpty, first, map, filter, random } from 'underscore';
import moment from 'moment';

const {
    DATE_FORMAT,
    TASK_STORAGE_NAME,
    STATUS_OUTSTANDING,
    STATUS_COMPLETED,
    PRIORITY_LOW,
    PRIORITY_MEDIUM,
    PRIORITY_HIGH,
} = require('../utils/values').default;

/**
 * Get list of tasks
 * @return {[type]} [description]
 */
const getTasks = () => {
    const tasks = localStorage.getItem(TASK_STORAGE_NAME);
    return JSON.parse(tasks) || [];
};

/**
 * Save a task to the list
 * @param  object task
 * @return void
 */
const saveTask = (task) => {
    const tasks = getTasks();
    const newTask = task;
    newTask.id = !isEmpty(first(tasks)) ? first(tasks).id + 1 : 1;

    tasks.unshift(newTask);
    localStorage.setItem(TASK_STORAGE_NAME, JSON.stringify(tasks));
};

/**
 * Remove a task from the list
 * @param  number taskId
 * @return void
 */
const removeTask = (taskId) => {
    const tasks = getTasks();
    const newTasks = filter(tasks, task => (task.id !== taskId));

    localStorage.setItem(TASK_STORAGE_NAME, JSON.stringify(newTasks));
};

/**
 * Mark a task as completed
 * @param  number taskId
 * @return void
 */
const completeTask = (taskId) => {
    const tasks = getTasks();
    const newTasks = map(tasks, (task) => {
        const newTask = task;
        if (task.id === taskId) {
            newTask.status = STATUS_COMPLETED;
            newTask.completed = moment().format(`${DATE_FORMAT} HH:mm:ss`);
        }
        return newTask;
    });

    localStorage.setItem(TASK_STORAGE_NAME, JSON.stringify(newTasks));
};

/**
 * Initialise tasks data if not existing
 * @return void
 */
const initialiseTasks = () => {
    if (isEmpty(getTasks())) {
        const tasks = [];
        for (let i = 12; i > 0; i--) {
            const rand = random(1, 15);
            let priority = PRIORITY_LOW;
            if (rand < 7) {
                priority = PRIORITY_MEDIUM;
            }
            if (rand < 3) {
                priority = PRIORITY_HIGH;
            }

            const task = {
                id: i,
                name: `Test task ${i}`,
                description: `Test task description ${i}`,
                due: moment().add(rand, 'd').format(DATE_FORMAT),
                priority,
                status: STATUS_OUTSTANDING
            };

            if (!random(2)) {
                task.status = STATUS_COMPLETED;
                task.completed = moment().subtract(rand, 'h').format(`${DATE_FORMAT} HH:mm:ss`);
            }

            tasks.push(task);
        }

        localStorage.setItem(TASK_STORAGE_NAME, JSON.stringify(tasks));
    }
}

export {
    getTasks,
    saveTask,
    removeTask,
    completeTask,
    initialiseTasks,
};
