import React from 'react';
import { Table } from 'antd';

export default function () {
    return (
        <Table
            pagination={false}
            rowKey="id"
            scroll={{ y: 600 }}
            columns={this.getTableColumns()}
            dataSource={this.getTableData()} />
    );
}
