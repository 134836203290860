import React, { Component } from 'react';
import { filter } from 'underscore';
import { Icon } from 'antd';

import { getTasks } from '../../utils/storage';

import DOM from './completedTasks';

const { STATUS_COMPLETED } = require('../../utils/values').default;

class CompletedTasks extends Component {

    constructor(props) {
        super(props);
        this.render = DOM;
        this.state = {};
    }

    // Get array config of table columns
    getTableColumns = () => {
        return [
            { title: 'Task ID', dataIndex: 'id', render: value => `#${value}` },
            { title: 'Task Name', dataIndex: 'name' },
            { dataIndex: 'priority', className: 'text-center priority', render: this.renderPriority },
            { title: 'Completed', dataIndex: 'completed', className: 'text-center' },
        ];
    }

    // Get array of table data
    getTableData = () => filter(getTasks(), task => (task.status === STATUS_COMPLETED));

    // Render priority cell with icon
    renderPriority = priority => {
        let type = "";
        switch (priority) {
            case 20:
                type = "right";
                break;

            case 30:
                type = "double-right";
                break;

            default:
                type = "left";
                break;
        }

        return <Icon type={type} />
    }
}

export default CompletedTasks;
