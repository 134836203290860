import React, { Component } from 'react';
import { Icon } from 'antd';

import DOM from './app';

class App extends Component {

    constructor(props) {
        super(props);
        this.render = DOM;
        this.state = {
            update: false,
        };
    }

    // Trigger component update
    handleTasksUpdate = () => this.setState({ update: !this.state.update });

    // Render tab title
    renderTabTitle = (icon, title) => <span><Icon type={icon}/>{title}</span>;
}

export default App;
