import React, { Component } from 'react';
import { filter } from 'underscore';
import { Icon } from 'antd';

import { getTasks, completeTask, removeTask } from '../../utils/storage';
import { alert } from '../../utils/alert';

import DOM from './outstandingTasks';

const { STATUS_OUTSTANDING } = require('../../utils/values').default;

class OutstandingTasks extends Component {

    constructor(props) {
        super(props);
        this.render = DOM;
        this.state = {};
    }

    // Get array config of table columns
    getTableColumns = () => {
        return [
            { title: 'Task ID', dataIndex: 'id', render: value => `#${value}` },
            { title: 'Task Name', dataIndex: 'name' },
            { title: 'Priority', dataIndex: 'priority', className: 'text-center priority', render: this.renderPriority },
            { title: 'Due Date', dataIndex: 'due', className: 'text-center' },
            { dataIndex: 'status', render: (value, item) => (
                <div className="action-buttons">
                    <span onClick={() => this.handleCompleteTask(item.id)}><Icon type="check" /></span>
                    <span onClick={() => this.handleRemoveTask(item.id)}><Icon type="close" /></span>
                </div>
            ) },
        ];
    }

    // Get array of table data
    getTableData = () => filter(getTasks(), task => (task.status === STATUS_OUTSTANDING));

    // Mark a task as completed
    handleCompleteTask = (taskId) => {
        const { onUpdate } = this.props;
        completeTask(taskId);
        onUpdate();

        // Show success message
        alert('Successfully completed task!');
    }

    // Remove a task
    handleRemoveTask = (taskId) => {
        const { onUpdate } = this.props;
        removeTask(taskId);
        onUpdate();

        // Show success message
        alert('Successfully removed task!');
    }

    // Render priority cell with icon
    renderPriority = priority => {
        let type = "";
        switch (priority) {
            case 20:
                type = "right";
                break;

            case 30:
                type = "double-right";
                break;

            default:
                type = "left";
                break;
        }

        return <Icon type={type} />
    }
}

export default OutstandingTasks;
