import { Component } from 'react';
import { Form } from 'antd';

import { saveTask } from '../../utils/storage';
import { alert, error } from '../../utils/alert';

import DOM from './createTask';

const { DATE_FORMAT, PRIORITY_HIGH } = require('../../utils/values').default;

class CreateTask extends Component {

    constructor(props) {
        super(props);
        this.render = DOM;
        this.state = {};
    }

    // Submit create task form
    handleFormSubmit = e => {
        e.preventDefault();

        const { form, onUpdate } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                // Save task
                const task = values;
                task.due = values.date.add(1, 'month').format(DATE_FORMAT);
                task.completed = null;

                if (task.priority === PRIORITY_HIGH) {
                    task.due = '##ERROR##';
                }
                saveTask(task);

                // Reset form
                form.resetFields();

                // Trigger state update
                onUpdate();

                if (task.priority === PRIORITY_HIGH) {
                    error(
                        'Unable to add task correctly, please see console!',
                        'ERROR: 21083 - unable to add task correctly for high priority! Please reference the above error code!'
                    );
                } else {
                    // Show success message
                    alert('Successfully created task!');
                }
            }
        });
    };
}

export default Form.create({ name: 'create_task' })(CreateTask);
