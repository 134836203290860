// CONST VALUES
const values = {
    TASK_STORAGE_NAME: 'tasks',

    STATUS_OUTSTANDING: 10,
    STATUS_COMPLETED: 20,

    PRIORITY_LOW: 10,
    PRIORITY_MEDIUM: 20,
    PRIORITY_HIGH: 30,

    DATE_FORMAT: 'DD/MM/YYYY',
};

export default values;
